import gql from "graphql-tag"

const BASE_PLUGIN_FIELDS = `
  _id
  tags
  desc
  cover
  status
  version
  platform
  author {
    _id
    avatar
    jd_nickname
  }
  content
  category
  displayName
  createdAt
  editedAt
`

export const PLUGINS = gql`
  query($filter: PluginFilter, $pageInfo: PageInfo) {
    res: plugins(filter: $filter, pageInfo: $pageInfo) {
      count
      plugins {
        ${BASE_PLUGIN_FIELDS}
        starCount
        downloadCount
        commentCount
      }
    }
  }
`

export const MY_PLUGINS = gql`
  query( $pageInfo: PageInfo) {
    res: myPlugins( pageInfo: $pageInfo) {
      count
      plugins {
        ${BASE_PLUGIN_FIELDS}
        starCount
        downloadCount
        commentCount
      }
    }
  }
`

export const USER_PLUGINS = gql`
  query($userId: ID!, $pageInfo: PageInfo) {
    res: userPlugins(userId: $userId,pageInfo: $pageInfo) {
      count
      plugins {
        ${BASE_PLUGIN_FIELDS}
        starCount
        downloadCount
        commentCount
      }
    }
  }
`

export const PLUGIN_SEARCH = gql`
  query($keyword: String, $pageInfo: PageInfo,$filter:SearchFilter) {
    res: searchPlugins(keyword: $keyword, pageInfo: $pageInfo,filter:$filter) {
      count
      plugins {
        ${BASE_PLUGIN_FIELDS}
        starCount
        downloadCount
        commentCount
      }
    }
  }
`

export const PLUGIN = gql`
  query($id: ID!) {
    plugin(id: $id) {
      ${BASE_PLUGIN_FIELDS}
      npmUrl
      gitUrl
      filePath
      downloadCount
    }
  }
`

export const CREATE_PLUGIN = gql`
  mutation($plugin: PluginCreateInput!) {
    plugin: createPlugin(plugin: $plugin) {
      _id
    }
  }
`

export const UPDATE_PLUGIN = gql`
  mutation($id: ID!, $plugin: PluginUpdateInput!) {
    plugin: updatePlugin(id: $id, plugin: $plugin) {
      ${BASE_PLUGIN_FIELDS}
      npmUrl
      gitUrl
      filePath
      downloadCount
    }
  }
`

export const INCREMENT_COUNT = gql`
  mutation($id: ID!) {
    increaseCount(id: $id)
  }
`

export const PLUGIN_COUNT = gql`
  query($filter: PluginFilter) {
    count: pluginCount(filter: $filter)
  }
`

export const PLUGIN_REVIEW = gql`
  mutation($id: ID!) {
    plugin: reviewPlugin(id: $id) {
      _id
      status
    }
  }
`

export const PLUGIN_OFF_SHELVES = gql`
  mutation($id: ID!) {
    plugin: offShelvesPlugin(id: $id) {
      _id
      status
    }
  }
`

export const PLUGIN_DELETE = gql`
  mutation($id: ID!) {
    plugin: deletePlugin(id: $id)
  }
`
