import React from "react"
import _isEmpty from "lodash/isEmpty"

import { Query } from "react-apollo"
import { Pagination } from "at-ui-nerv"
import { withRouter } from "react-router-dom"

import PluginItem from "../item/index"
import Empty from "../../../component/common/empty"
import Loading from "../../../component/common/loading"

import { PLUGINS } from "../../../gql/plugin"
import {
  getValueByKey,
  pickQueryString,
  changeValueByKey
} from "../../../utils/query-string"

const LIMIT = 10

class PluginList extends React.Component {
  handlePageChange = page => {
    const { history } = this.props
    const search = changeValueByKey("page", page, {
      isStringify: true
    })
    history.push({
      search
    })
  }

  render () {
    const page = Number(getValueByKey("page")) || 1
    const filter = pickQueryString(["sort", "displayName"])

    return (
      <Query
        query={PLUGINS}
        variables={{
          filter,
          pageInfo: {
            skip: (page - 1) * LIMIT
          }
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <Loading />
          }

          const { count, plugins } = data.res

          if (_isEmpty(plugins)) {
            return <Empty />
          }

          return (
            <div className="plugin-list">
              {plugins.map(item => (
                <PluginItem key={item._id} {...item} />
              ))}
              <Pagination
                showTotal
                total={count}
                current={page}
                onPageChange={this.handlePageChange}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(PluginList)
