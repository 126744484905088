import React from "react"

import _isEmpty from "lodash/isEmpty"
import queryString from "query-string"

import { Input } from "at-ui-nerv"
import { withRouter } from "react-router-dom"
import { Query } from "react-apollo"

import { PLUGIN_COUNT } from "../../../gql/plugin"
import { PLUGIN_REVIEW_FINISH } from "../../../../../const/status"
import { getValueByKey, changeValueByKey } from "../../../utils/query-string"

import "./index.scss"

class SearchInput extends React.PureComponent {
  constructor (props) {
    super(...arguments)

    this.state = {
      value: props.value || getValueByKey("keyword")
    }
  }

  componentWillReceiveProps (props) {
    if (props.location !== this.props.location) {
      this.setState({
        value: getValueByKey("keyword")
      })
    }
  }

  handleSearch = e => {
    e.preventDefault()
    const { value } = this.state
    const { history } = this.props
    let searchStr = ""

    if (!_isEmpty(value)) {
      searchStr = queryString.stringify({
        keyword: value
      })
    }

    history.push({
      pathname: "/search",
      search: searchStr
    })
  }

  handleChange = value => {
    this.setState({
      value
    })
  }

  render () {
    const { value } = this.state

    return (
      <form className="search-input" onSubmit={this.handleSearch}>
        <Query
          query={PLUGIN_COUNT}
          variables={{
            filter: {
              status: PLUGIN_REVIEW_FINISH
            }
          }}
        >
          {({ data }) => {
            return (
              <Input
                size="large"
                value={value}
                onInput={this.handleChange}
                placeholder={
                  data.count ? `共 ${data.count} 个物料` : "欢迎贡献您的物料"
                }
                append={
                  <i onClick={this.handleSearch} className="icon icon-search" />
                }
              />
            )
          }}
        </Query>
      </form>
    )
  }
}

export default withRouter(SearchInput)
