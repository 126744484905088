import React from "react"

import { Query, Mutation } from "react-apollo"
import { Pagination, Table, Button } from "at-ui-nerv"
import { withRouter, Link } from "react-router-dom"

import { getJfsUrl } from "../../../utils/helper"

import Loading from "@web/component/common/loading"

import { ADMIN_USERS } from "../../../../../const/index"

import { COMMENT_NORMAL, COMMENT_REVIEWED } from "../../../../../const/status"

import client from "../../../base/graphql"

import {
  COMMENTS,
  COMMENT_REVIEW,
  COMMENT_OFF_SHELVES
} from "../../../gql/comment"
import {
  getValueByKey,
  pickQueryString,
  changeValueByKey
} from "../../../utils/query-string"
import withMe from "../../../hoc/me"

const LIMIT = 10

const COLUMNS = [
  {
    title: "资源ID",
    component: item => {
      return (
        <div>
          <a href={`/plugin/view/${item.categoryId}`}>{item.categoryId}</a>
        </div>
      )
    }
  },
  {
    title: "作者",
    component: item => {
      return <div>{item.user.jd_nickname}</div>
    }
  },
  {
    title: "内容",
    key: "content"
  },
  {
    title: "评分",
    key: "rate"
  },
  {
    title: "状态",
    component: item => {
      return <div>{item.status === COMMENT_REVIEWED ? "已审核" : "未审核"}</div>
    }
  },
  {
    title: "操作",
    component: item => {
      return (
        <Button.Group>
          <Button
            disabled={item.status !== COMMENT_NORMAL}
            onClick={() => {
              client.mutate({
                mutation: COMMENT_REVIEW,
                variables: {
                  id: item._id
                }
              })
            }}
          >
            审核通过
          </Button>
          <Button
            disabled={item.status === COMMENT_NORMAL}
            onClick={() => {
              client.mutate({
                mutation: COMMENT_OFF_SHELVES,
                variables: {
                  id: item._id
                }
              })
            }}
          >
            下架
          </Button>
        </Button.Group>
      )
    }
  }
]

class PluginList extends React.Component {
  handlePageChange = page => {
    const { history } = this.props
    const search = changeValueByKey("page", page, {
      isStringify: true
    })
    history.push({
      search
    })
  }

  render () {
    const page = Number(getValueByKey("page")) || 1
    const filter = pickQueryString(["status"])

    filter.status = filter.status ? parseInt(filter.status) : COMMENT_NORMAL

    return (
      <div className="page--console page">
        <div className="filter-bar">
          <Link to={`?status=${COMMENT_NORMAL}`}>未审核</Link>
          <Link to={`?status=${COMMENT_REVIEWED}`}>已审核</Link>
        </div>
        <Query
          query={COMMENTS}
          variables={{
            filter,
            pageInfo: {
              skip: (page - 1) * LIMIT
            }
          }}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return <Loading />
            }
            if (error) {
              return <pre>{error}</pre>
            }

            const { count, comments } = data.res

            return (
              <div className="comment-list">
                <Table stripe border columns={COLUMNS} data={comments} />
                <Pagination
                  showTotal
                  total={count}
                  current={page}
                  onPageChange={this.handlePageChange}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withMe(withRouter(PluginList), {
  forceAuth: true,
  guard: me => {
    return ADMIN_USERS.includes(me.username)
  }
})
