var _require = require("./index"),
    PLUGIN_UI = _require.PLUGIN_UI,
    PLUGIN_SDK = _require.PLUGIN_SDK,
    PLUGIN_TEMPLATES = _require.PLUGIN_TEMPLATES,
    PLUGIN_COMPONENT = _require.PLUGIN_COMPONENT;

var _require2 = require("./status"),
    COMMENT_NORMAL = _require2.COMMENT_NORMAL,
    COMMENT_REVIEWED = _require2.COMMENT_REVIEWED,
    PLUGIN_NORMAL = _require2.PLUGIN_NORMAL,
    PLUGIN_REVIEW_FINISH = _require2.PLUGIN_REVIEW_FINISH,
    PLUGIN_REVIEW_PENDING = _require2.PLUGIN_REVIEW_PENDING;

var _require3 = require("./platform"),
    H5 = _require3.H5,
    WEAPP = _require3.WEAPP,
    SMART = _require3.SMART,
    ALIPAY = _require3.ALIPAY,
    TOUTIAO = _require3.TOUTIAO,
    QUICK_APP = _require3.QUICK_APP,
    REACT_NATIVE = _require3.REACT_NATIVE,
    QQ = _require3.QQ,
    JD = _require3.JD;

module.exports = {
  COMMENT_STATUS: [COMMENT_NORMAL, COMMENT_REVIEWED],
  PLUGIN_STATUS: [PLUGIN_NORMAL, PLUGIN_REVIEW_FINISH, PLUGIN_REVIEW_PENDING],
  PLUGIN_CATEGORYS: [PLUGIN_COMPONENT, PLUGIN_SDK, PLUGIN_TEMPLATES, PLUGIN_UI],
  PLUGIN_CATEGORYS_MAP: new Map([[PLUGIN_COMPONENT, {
    name: "component",
    displayName: "组件",
    value: PLUGIN_COMPONENT
  }], [PLUGIN_SDK, {
    name: "sdk",
    displayName: "SDK",
    value: PLUGIN_SDK
  }], [PLUGIN_TEMPLATES, {
    name: "template",
    displayName: "模板",
    value: PLUGIN_TEMPLATES
  }], [PLUGIN_UI, {
    name: "ui",
    displayName: "UI",
    value: PLUGIN_UI
  }]]),
  PLATFORM_MAP: new Map([[H5, {
    name: "h5",
    displayName: "H5",
    value: H5
  }], [WEAPP, {
    name: "weapp",
    displayName: "微信小程序",
    value: WEAPP
  }], [SMART, {
    name: "smart",
    displayName: "百度小程序",
    value: SMART
  }], [ALIPAY, {
    name: "alipay",
    displayName: "支付宝小程序",
    value: ALIPAY
  }], [TOUTIAO, {
    name: "toutiao",
    displayName: "头条小程序",
    value: TOUTIAO
  }], [QUICK_APP, {
    name: "quick_app",
    displayName: "快应用",
    value: QUICK_APP
  }], [REACT_NATIVE, {
    name: "react_native",
    displayName: "React Native",
    value: REACT_NATIVE
  }], [QQ, {
    name: "qq",
    displayName: "QQ 小程序",
    value: QQ
  }], [JD, {
    name: "jd",
    displayName: "京东小程序",
    value: JD
  }]])
};