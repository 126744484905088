import React from "react"
import classnames from "classnames"

import "./index.scss"

class FormItem extends React.PureComponent {
  render () {
    const { tip, isRequired, label, labelFor, className } = this.props

    const rootClass = classnames("form-item", className, {
      "form-item--required": isRequired
    })

    return (
      <div className={rootClass}>
        <div className="form-item__field">
          <label className="field-label" for={labelFor}>
            {label}
          </label>
          {tip ? <span className="field-tip">{tip}</span> : null}
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default FormItem
