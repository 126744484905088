import React from "react"
import classnames from "classnames"

import "./index.scss"

class Empty extends React.Component {
  render () {
    const { style, className } = this.props

    const rootClass = classnames("app-empty", className)

    return (
      <div className={rootClass} style={style}>
        <i class="icon icon-inbox" />
        暂无数据
      </div>
    )
  }
}

export default Empty
