import React from "react"
import _isEmpty from "lodash/isEmpty"

import { Query } from "react-apollo"
import { Pagination } from "at-ui-nerv"

import Empty from "../../../component/common/empty"
import Loading from "../../../component/common/loading"
import PluginItem from "../../../component/my/plugin/item/index"

import { MY_PLUGINS } from "../../../gql/plugin"

import { getValueByKey, changeValueByKey } from "../../../utils/query-string"

const LIMIT = 10

class MyPluginList extends React.PureComponent {
  handlePageChange = page => {
    const { history } = this.props
    const search = changeValueByKey("page", page, {
      isStringify: true
    })
    history.push({
      search
    })
  }

  render () {
    const page = Number(getValueByKey("page")) || 1

    const variables = {
      pageInfo: {
        skip: (page - 1) * LIMIT
      }
    }

    return (
      <div className="page page--my-plugin">
        <Query fetchPolicy='network-only' query={MY_PLUGINS} variables={variables}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />
            }

            const { count, plugins } = data.res

            if (_isEmpty(plugins)) {
              return <Empty />
            }

            return (
              <div className="my-plugin-list">
                {plugins.map(item => (
                  <PluginItem variables={variables} key={item._id} {...item} />
                ))}
                <Pagination
                  showTotal
                  total={count}
                  current={page}
                  onPageChange={this.handlePageChange}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default MyPluginList
