import React from "react"
import _uniq from "lodash/uniq"
import _isEmpty from "lodash/isEmpty"

import { Query } from "react-apollo"
import { Pagination, Checkbox } from "at-ui-nerv"
import { withRouter } from "react-router-dom"

import PluginItem from "../../plugin/item/index"
import Loading from "../../../component/common/loading"
import Empty from "../../../component/common/empty"

import { PLUGIN_SEARCH } from "../../../gql/plugin"
import { PLUGIN_CATEGORYS_MAP } from "../../../../../const/advanced"
import { getValueByKey, changeValueByKey } from "../../../utils/query-string"

import "./index.scss"

const LIMIT = 10

const CATEGORY_ARRAY = [...PLUGIN_CATEGORYS_MAP.values()]

class SearchList extends React.Component {
  handlePageChange = page => {
    const { history } = this.props
    const search = changeValueByKey("page", page, {
      isStringify: true
    })
    history.push({
      search
    })
  }

  // handleCategoryChange = category => {
  //   const { history } = this.props
  //   const search = changeValueByKey("category", category, {
  //     isStringify: true
  //   })

  //   history.push({
  //     search
  //   })
  // }

  render () {
    const keyword = getValueByKey("keyword")
    const page = Number(getValueByKey("page")) || 1
    // let category = getValueByKey("category")

    // if (category && !Array.isArray(category)) {
    //   category = [category]
    // }

    // if (Array.isArray(category)) {
    //   category = category.map(item => Number(item))
    // }

    const variables = {
      keyword,
      // filter: {
      //   category
      // },
      pageInfo: {
        skip: (page - 1) * LIMIT
      }
    }

    return (
      <div className="plugin-list search-plugin-list">
        {/* <div className="search-plugin-list__filter">
          <Checkbox.Group
            value={category || []}
            onChange={this.handleCategoryChange}
          >
            {CATEGORY_ARRAY.map(item => {
              return (
                <Checkbox key={item.value} label={item.value}>
                  {item.displayName}
                </Checkbox>
              )
            })}
          </Checkbox.Group>
        </div> */}

        <Query
          query={PLUGIN_SEARCH}
          variables={variables}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loading />
            }

            const { count, plugins } = data.res

            if (_isEmpty(plugins)) {
              return <Empty />
            }

            return (
              <div>
                {plugins.map(item => (
                  <PluginItem key={item._id} {...item} isTagLink />
                ))}
                <Pagination
                  showTotal
                  total={count}
                  current={page}
                  onPageChange={this.handlePageChange}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withRouter(SearchList)
