import React from "react"

import "./index.scss"

class UserCard extends React.PureComponent {
  render () {
    const { avatar, jd_nickname: nickname } = this.props
    return (
      <div className="user-card">
        <div className="user-info">
          <div className="avatar">
            <img src={avatar} alt="" />
          </div>
          <span className="nickname">{nickname}</span>
        </div>
      </div>
    )
  }
}

export default UserCard
