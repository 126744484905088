import React from "react"

import NavLink from "../../common/nav-link/index"
import { getValueByKey } from "../../../utils/query-string"

import "./index.scss"

class Ranking extends React.Component {
  render () {
    return (
      <div className="at-tabs__header">
        <div className="at-tabs__extra">
          <div />
        </div>

        <div className="at-tabs__nav">
          <div className="at-tabs__nav-wrap">
            <div className="at-tabs__nav-scroll">
              <div className="at-tabs-nav">
                <NavLink
                  className="at-tabs-nav__item"
                  activeClassName="at-tabs-nav__item--active"
                  to="/"
                  isActive={(match, location) => {
                    return match && !getValueByKey("sort")
                  }}
                >
                  最新发布
                </NavLink>
                <NavLink
                  className="at-tabs-nav__item"
                  activeClassName="at-tabs-nav__item--active"
                  to="/?sort=monthHot"
                >
                  最近热门
                </NavLink>
                <NavLink
                  className="at-tabs-nav__item"
                  activeClassName="at-tabs-nav__item--active"
                  to="/?sort=hot"
                >
                  热门榜单
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ranking
