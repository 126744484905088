
import React from "react"
import gql from "graphql-tag"
import _head from "lodash/head"
import _isEmpty from "lodash/isEmpty"
import _startsWith from "lodash/startsWith"

import { Message } from "at-ui-nerv"
import { getFileSize } from "../../../utils/helper"

import client from "../../../base/graphql"

import "./index.scss"

const UPLOAD_JFS_FILE = gql`
  mutation($file: Upload!) {
    file: uploadFile(file: $file) {
      filename
      mimetype
      path
    }
  }
`

const UPLOAD_FILE = gql`
  mutation($file: Upload!) {
    file: saveFile(file: $file) {
      filename
      mimetype
      path
    }
  }
`

export default class UploadFile extends React.Component {
  state = {
    isLoading: false
  }

  handleChange = async e => {
    const { isMulti, isJFS = true, maxSize = 1024 * 1024 * 5 } = this.props
    const { validity, files } = e.target

    if (!validity.valid) {
      return Message.error("文件不符合要求")
    }

    const variable = {}

    if (isMulti) {
      return Message.error("暂不支持多文件上传")
      // variable.files = files
    }
    variable.file = _head(files)

    if (variable.file.size > maxSize) {
      return Message.error(`只允许上传 ${getFileSize(maxSize)} 大小`)
    }

    this.setState({
      isLoading: true
    })

    try {
      const { data } = await client.mutate({
        mutation: isJFS ? UPLOAD_JFS_FILE : UPLOAD_FILE,
        variables: variable,
        context: {
          isUpload: true
        }
      })

      if (_startsWith(data.file.mimetype, "image") && _isEmpty(data.file.path)) {
        Message.error("图片上传失败，可能由于涉及敏感、黄色内容或不符合上传格式")
      } else {
        this.props.onUploadFinish && this.props.onUploadFinish(data)
      }
    } catch (e) {
      Message.error("上传失败 请重新尝试")
    }

    this.setState({
      isLoading: false
    })
  }

  render () {
    const { isLoading } = this.state
    const { accept } = this.props
    return (
      <div className="app-upload">
        {!isLoading ? <i className="icon icon-upload-cloud" /> : null}
        {isLoading ? (
          <div className="upload-loadding">Loading...</div>
        ) : (
          <input
            className="upload-input"
            type="file"
            onChange={this.handleChange}
            accept={accept}
          />
        )}
      </div>
    )
  }
}
