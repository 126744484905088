import React from "react"
import { Breadcrumb } from "at-ui-nerv"
import { Route, Link, Switch, NavLink } from "react-router-dom"
import MyStars from "./star/index"
import MyPlugins from "./plugin/index"

import "./index.scss"
import withMe from "../../hoc/me"

class MyPage extends React.PureComponent {
  render () {
    return (
      <div className="page page--my main">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">物料市场</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>个人中心</Breadcrumb.Item>
        </Breadcrumb>
        <div className="at-tabs__header">
          <div className="at-tabs__extra">
            <div />
          </div>
          <div className="at-tabs__nav">
            <div className="at-tabs__nav-wrap">
              <div className="at-tabs__nav-scroll">
                <div className="at-tabs-nav">
                  <NavLink
                    className="at-tabs-nav__item"
                    activeClassName="at-tabs-nav__item--active"
                    to="/my/plugin"
                  >
                    我的物料
                  </NavLink>
                  <NavLink
                    className="at-tabs-nav__item"
                    activeClassName="at-tabs-nav__item--active"
                    to="/my/star"
                  >
                    我的收藏
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route path="/my/star" component={MyStars} />
          <Route path="/my/plugin" component={MyPlugins} />
        </Switch>
      </div>
    )
  }
}

export default withMe(MyPage, {
  forceAuth: true
})
