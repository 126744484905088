import gql from "graphql-tag"

export const COMMENTS = gql`
  query($filter: CommentFilter, $pageInfo: PageInfo) {
    res: comments(filter: $filter, pageInfo: $pageInfo) {
      count
      comments {
        _id
        rate
        status
        content
        createdAt
        category
        categoryId
        user {
          _id
          avatar
          jd_nickname
        }
        replyComment {
          _id
          rate
          content
          user {
            _id
            jd_nickname
          }
        }
      }
    }
  }
`

export const CREATE_COMMENT = gql`
  mutation($comment: CommentCreateInput) {
    comment: createComment(comment: $comment) {
      _id
      rate
      content
      category
      categoryId
      createdAt
      user {
        _id
        avatar
        jd_nickname
      }
      replyComment {
        _id
        rate
        content
        user {
          _id
          jd_nickname
        }
      }
    }
  }
`

export const COMMENT_REVIEW = gql`
  mutation($id: ID!) {
    comment: reviewComment(id: $id) {
      _id
      status
    }
  }
`

export const COMMENT_OFF_SHELVES = gql`
  mutation($id: ID!) {
    comment: offShelvesComment(id: $id) {
      _id
      status
    }
  }
`
