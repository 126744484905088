import React from "react"
import { Route, Switch } from "react-router-dom"

import _ from "lodash"

import withMe from "../hoc/me"

import NoMatch from "../pages/404"
import Home from "../pages/home/index"
import Search from "../pages/search/index"

import PluginForm from "../pages/plugin/form/index"
import PluginDetail from "../pages/plugin/detail/index"

import MyPage from "../pages/my/index"

import SpacePage from "../pages/space"
import ConsolePage from "../pages/console"

const Routes = props => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/search" component={Search} />
      <Route path="/plugin/form/:id?" component={PluginForm} />
      <Route path="/plugin/view/:id" component={PluginDetail} />

      <Route path="/my" component={MyPage} />
      <Route path="/space/:id" component={SpacePage} />
      <Route path="/console" component={ConsolePage} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default withMe(Routes)
