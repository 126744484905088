import _get from "lodash/get"
import _omit from "lodash/omit"
import _pick from "lodash/pick"

import queryString from "query-string"

import { isUrl } from "./helper"

/**
 * 获取 字符创 解析的查询对象
 * @param {Object} options 可选参数 详细可参考 https://github.com/sindresorhus/query-string
 * @param {String} str 需要解析的字符串
 */
const getQuery = (str = window.location.search, options) => {
  let query = {}

  if (isUrl(str)) {
    query = queryString.parseUrl(str, options).query
  } else {
    query = queryString.parse(str, options)
  }
  return query
}

/**
 * 获取 key 对应的值
 * @param {String} key
 * @param {Object} options 可选参数 详细可参考 https://github.com/sindresorhus/query-string
 * @param {String} str 需要解析的字符串
 */
export const getValueByKey = (key, options, str) => {
  const query = getQuery(str, options)
  return _get(query, key)
}

/**
 * 更换 key 下面对应的值
 * @param {String[] | String} keys
 * @param {any[] | any} values
 * @param {Object} options 可选参数 详细可参考 https://github.com/sindresorhus/query-string
 * @param {Object} options.isStringify 可选参数 结果是否返回解析后的字符创 默认为否 返回 查询对象
 * @param {String} str 需要解析的字符串
 */
export const changeValueByKey = (keys, values, options = {}, str) => {
  const { isStringify } = options
  const query = getQuery(str, _omit(options, ["isStringify"]))

  if (Array.isArray(keys)) {
    keys.forEach((value, index) => {
      query[value] = values[index]
    })
  } else {
    query[keys] = values
  }

  return isStringify ? queryString.stringify(query) : query
}

/**
 * 挑选 query对象 需要的字段
 * @param {*} array lodash pick
 * @param {Object} options 可选参数 详细可参考 https://github.com/sindresorhus/query-string
 * @param {String} str 需要解析的字符串
 */
export const pickQueryString = (array, str, options) => {
  const query = getQuery(str, options)
  return _pick(query, array)
}

/**
 * 去除 query 不需要的字段
 * @param {*} array lodash omit
 * @param {Object} options 可选参数 详细可参考 https://github.com/sindresorhus/query-string
 * @param {String} str 需要解析的字符串
 */
export const omitQueryString = (array, str, options) => {
  const query = getQuery(str, options)
  return _omit(query, array)
}
