module.exports = {
  PLUGIN_NORMAL: 0,
  // 插件 刚创建 未审核
  PLUGIN_REVIEW_PENDING: 1,
  // 插件 特殊处理完成 (例如 MD 解析) 可审核
  PLUGIN_REVIEW_FINISH: 2,
  // 插件 已审核
  COMMENT_NORMAL: 0,
  // 评论 刚创建 未审核
  COMMENT_REVIEWED: 1 // 评论 已审核

};