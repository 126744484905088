import _get from "lodash/get"

import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"

import { createUploadLink } from "apollo-upload-client"

import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import { DedupLink } from "apollo-link-dedup"
import { BatchHttpLink } from "apollo-link-batch-http"

import { Message } from "at-ui-nerv"

import { BAD_PARAMS } from "../../../const/index"
import { joiTipGenerate } from "../utils/helper"

const HTTP_CONFIG = {
  batchMax: 12,
  uri: "/graphql",
  credentials: "include"
}

const dedupLink = new DedupLink()

const batchHttpLink = new BatchHttpLink(HTTP_CONFIG)

const uploadLink = createUploadLink(HTTP_CONFIG)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(item => {
      const { message, extensions } = item
      if (extensions.code === "UNAUTHENTICATED") {
        Message.error("请先登录")
      }
      if (message === BAD_PARAMS) {
        const type = _get(extensions, "code.error.details[0].type")
        const ctx = _get(extensions, "code.error.details[0].context", {})
        const tip = joiTipGenerate(type, ctx) || `未知错误 ${type}`
        Message.error(tip)
      }
      console.error(item)
    })
  }
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        case "Status":
          return object.categoryId + object.category
        default:
          return object.id || object._id
      }
    }
  }),
  connectToDevTools: true,
  link: ApolloLink.from([
    errorLink,
    dedupLink,
    ApolloLink.split(
      operation => {
        const ctx = operation.getContext()

        return ctx.isUpload
      },
      uploadLink,
      batchHttpLink
    )
  ])
})

export default client
