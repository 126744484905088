import React from "react"

import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _isFunction from "lodash/isFunction"

import gql from "graphql-tag"
import { Message } from "at-ui-nerv"

import { Query } from "react-apollo"
import { Redirect } from "react-router-dom"

const QUERY_ME = gql`
  query {
    me {
      _id
      username
      jd_nickname
    }
  }
`

/**
 * 获取 用户 信息
 * @param {ReactComponent} Component 被封装的React组件
 * @param {Object} options 参数要求
 * @param {Boolean} options.forceAuth 是否强制登录
 * @param {Function} options.guard 守护函数
 */
const withMe = (Component, options = {}) => props => {
  const { forceAuth, guard } = options
  const { history = {} } = props
  return (
    <Query query={QUERY_ME}>
      {({ loading, error, data }) => {
        const me = _get(data, "me", {}) || {}

        if (loading) {
          return
        }

        if (error) {
          console.error(error)
        }

        // 要求 强制登录时 没有获取到 用户信息 跳转首页
        if (forceAuth && _isEmpty(me)) {
          Message.warning("请先登录")

          if (_isFunction(history.goBack)) {
            props.history.goBack()
            return null
          }

          return <Redirect to="/" />
        }

        if (guard && forceAuth && _isFunction(guard)) {
          const isAllow = guard(me)
          if (!isAllow) {
            return props.history.goBack()
          }
        }
        return <Component {...props} me={me} />
      }}
    </Query>
  )
}

export default withMe
