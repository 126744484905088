import React from "react"

import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import classnames from "classnames"

import { Button, Message } from "at-ui-nerv"
import { Query, Mutation } from "react-apollo"
import { NO_AUTHENTICATION } from "../../../../../../const/index"

import withMe from "../../../../hoc/me"
import { STATUS, STAR, UNSTAR } from "../../../../gql/star"

import "./index.scss"

class Star extends React.Component {
  handleToggleError = e => {
    const message = _get(e, "graphQLErrors[0].message")
    if (message === NO_AUTHENTICATION) {
      Message.error("先登录之后才可以收藏哦")
    }
  }

  handleToggle = async (action, isStar) => {
    const res = await action()

    if (_isEmpty(res)) {
      return
    }

    if (isStar) {
      Message.success("取消成功")
    } else {
      Message.success("收藏成功")
    }
  }

  render () {
    const { category, categoryId, status, autoCheck, me } = this.props

    const variable = {
      category,
      id: categoryId
    }

    const isSkip = _isEmpty(me) || !autoCheck

    return (
      <Query skip={isSkip} query={STATUS} variables={variable}>
        {({ loading, data, error }) => {
          if (_isEmpty(data)) {
            data = {
              status: {
                status
              }
            }
          }

          const isStar = data.status.status

          const rootClass = classnames("social-star", {
            "social-star--active": isStar
          })

          const iconClass = classnames("icon", {
            "icon-star": !isStar,
            "icon-star-on": isStar
          })

          return (
            <Mutation
              mutation={isStar ? UNSTAR : STAR}
              variables={variable}
              onError={this.handleToggleError}
            >
              {toggleStar => {
                return (
                  <Button
                    type="primary"
                    hollow={!isStar}
                    className={rootClass}
                    onClick={this.handleToggle.bind(this, toggleStar, isStar)}
                  >
                    <i className={iconClass} />{" "}
                    {isStar ? "取消收藏" : "立即收藏"}
                  </Button>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

Star.defaultProps = {
  autoCheck: true
}

export default withMe(Star)
