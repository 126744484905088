import gql from "graphql-tag"

export const STAR_RESOURCES = gql`
  query {
    stars {
      count
      resources {
        ... on Plugin {
          _id
          tags
          desc
          cover
          version
          author {
            _id
            avatar
            username
          }
          commentCount
          starCount
          category
          displayName
          createdAt
        }
      }
    }
  }
`

export const STATUS = gql`
  query($id: ID!, $category: Int!) {
    status(id: $id, category: $category) {
      status
      category
      categoryId
    }
  }
`

export const STAR = gql`
  mutation($id: ID!, $category: Int!) {
    star(id: $id, category: $category) {
      status
      category
      categoryId
    }
  }
`

export const UNSTAR = gql`
  mutation($id: ID!, $category: Int!) {
    unstar(id: $id, category: $category) {
      status
      category
      categoryId
    }
  }
`
