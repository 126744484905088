import React from "react"

import _isEmpty from "lodash/isEmpty"

import { Mutation } from "react-apollo"
import { Message, Button, Rate, Textarea } from "at-ui-nerv"

import client from "../../../../base/graphql"

import TextOverflow from "../../text-overflow/index"
import withMe from "../../../../hoc/me"

import { getLoginUrl } from "../../../../utils/helper"
import { CREATE_COMMENT, COMMENTS } from "../../../../gql/comment"
import { COMMENT_STATUS_REVIEWED } from "../../../../../../const/index"

import "./index.scss"

const TOTAL = 250

class CommentPublish extends React.Component {
  constructor () {
    super(...arguments)

    this.state = {
      rate: 0,
      content: ""
    }
  }

  publishComment = async action => {
    const { content, rate } = this.state
    const { me } = this.props

    if (!me._id) {
      return Message.error("只有登录后,才能发表评论哦")
    }

    if (!content) {
      return Message.error("内容 不能为空")
    }

    if (!rate) {
      return Message.error("评分 不能为空")
    }

    if (content.length > TOTAL) {
      return Message.error("评论不能超过 250 个字")
    }

    const { data } = await action()

    if (!_isEmpty(data)) {
      Message.info("评论发表成功")

      this.setState({
        rate: 0,
        content: ""
      })
    }
  }

  handleRateChange = value => {
    if (value && value !== this.state.rate) {
      this.setState({
        rate: value
      })
    }
  }

  handleInput = e => {
    const { value } = e.target
    this.setState({
      content: value
    })
  }

  handlePuhlish = e => {
    const { content } = this.state
    const { category = 1, categoryId, replyComment } = this.props

    const comment = {
      content,
      category,
      categoryId,
      replyComment
    }

    client.mutate({
      variables: {
        comment
      },
      mutation: CREATE_COMMENT
    })
  }

  render () {
    const { content, rate } = this.state
    const {
      me,
      className,
      categoryId,
      replyComment,
      category = 1,
      total = TOTAL
    } = this.props

    const variable = {
      comment: {
        rate,
        content,
        category,
        categoryId,
        replyComment
      }
    }

    return (
      <div className={`comment-publish ${className}`}>
        <div className="comment-publish__textarea">
          {me._id ? null : (
            <div className="lock-layer">
              <i className="icon icon-lock" />
              点击 <a href={getLoginUrl()}>这里</a> 登陆后留下你的评价
            </div>
          )}

          <Textarea
            minRows={4}
            maxRows={6}
            resize="none"
            value={content}
            placeholder="请输入你要评论的消息"
            onInput={this.handleInput}
          />
        </div>

        <div className="comment-publish__action">
          <div className="action-left">
            <Rate
              allowHalf
              value={rate}
              allowClear={false}
              onChange={this.handleRateChange}
            />
          </div>
          <div className="action-right">
            <TextOverflow use={content.length} total={total} />
            <Mutation
              variables={variable}
              mutation={CREATE_COMMENT}
              update={(cache, { data: { comment } }) => {
                const variables = {
                  filter: {
                    category,
                    categoryId,
                    status: COMMENT_STATUS_REVIEWED
                  }
                }

                const data = cache.readQuery({
                  variables,
                  query: COMMENTS
                })

                data.res.comments.unshift(comment)

                cache.writeQuery({
                  variables,
                  query: COMMENTS,
                  data: {
                    comments: data.comments
                  }
                })
              }}
            >
              {addComment => (
                <Button
                  className="action-button"
                  type="primary"
                  onClick={this.publishComment.bind(this, addComment)}
                >
                  发表
                </Button>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    )
  }
}

export default withMe(CommentPublish)
