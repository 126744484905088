import React from "react"
import dayjs from "dayjs"
import _isEmpty from "lodash/isEmpty"

import { Mutation } from "react-apollo"
import { Button, Tag, Tooltip } from "at-ui-nerv"

import Star from "../../../common/social/star/index"

import { INCREMENT_COUNT } from "../../../../gql/plugin"
import { PLATFORM_MAP } from "../../../../../../const/advanced"
import { PLUGIN as CONST_PLUGIN } from "../../../../../../const/index"

import npm from "@web/assets/npm.png"

import "./index.scss"

const Download = ({ _id, downloadCount, filePath }) => {
  return (
    <Mutation
      mutation={INCREMENT_COUNT}
      variables={{
        id: _id
      }}
    >
      {download => {
        return (
          <a href={filePath} alt="下载" download={filePath} onClick={download}>
            <Button type="success" hollow>
              <i className="icon icon-download" /> 下载插件
            </Button>
          </a>
        )
      }}
    </Mutation>
  )
}

class DetailBase extends React.PureComponent {
  render () {
    const {
      _id,
      tags,
      desc,
      gitUrl,
      npmUrl,
      version,
      filePath,
      platform,
      editedAt,
      displayName,
      downloadCount
    } = this.props

    return (
      <div className="base-container">
        <div className="base__btns">
          <Star categoryId={_id} category={CONST_PLUGIN} />
          <Download
            _id={_id}
            downloadCount={downloadCount}
            filePath={filePath}
          />
        </div>
        <h1 className="base__title">{displayName}</h1>
        <div className="base__tags">
          {tags.map(tag => {
            return <Tag color="#eee">{tag}</Tag>
          })}
        </div>
        <div className="base__desc">{desc}</div>
        <div className="base__status">
          <span>更新日期：{dayjs(editedAt).format("YYYY-MM-DD")}</span>
          <span>当前版本：{version}</span>
        </div>
        {gitUrl ? (
          <div className="base__link">
            <i className="icon icon-github" />
            <a href={gitUrl} target="_blank" rel="noopener noreferrer">
              {gitUrl}
            </a>
          </div>
        ) : null}
        {npmUrl ? (
          <div className="base__link">
            <img src={npm} alt="npm" />
            <a href={npmUrl} target="_blank" rel="noopener noreferrer">
              {npmUrl}
            </a>
          </div>
        ) : null}

        {!_isEmpty(platform) ? (
          <div className="base__platform">
            <span className="platform__text">支持平台：</span>
            <div className="platform__list">
              {platform.map((item, key) => {
                const info = PLATFORM_MAP.get(item)
                return (
                  <Tooltip content={info.displayName} placement="bottom">
                    <div
                      key={key}
                      className={`platform platform--${info.name}`}
                    />
                  </Tooltip>
                )
              })}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default DetailBase
