import React from "react"
import { Link } from "react-router-dom"
import { Header as TaroHeader } from "@jd/taro-header-footer"

import WithMe from "../../../hoc/me"
import { getLoginUrl, getSsoLogoutUrl } from "../../../utils/helper"

import "./index.scss"
import axios from "axios"

class Header extends React.Component {
  render () {
    const { me } = this.props
    return (
      <div className="app-header">
        <TaroHeader immersive >
          {me._id ? (
            <div className="header__user">
              <Link to="/my/plugin" className="username">
                {me.jd_nickname}
              </Link>
              <span className="logout" onClick={async () => {
                await axios(getSsoLogoutUrl(), {
                  withCredentials: true
                })
                window.location.reload()
              }} >
                退出
              </span>
            </div>
          ) : (
            <a className="login" href={getLoginUrl()}>
              登录
            </a>
          )}
        </TaroHeader>
      </div>
    )
  }
}

export default WithMe(Header)
