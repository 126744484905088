import React from "react"
import dayjs from "dayjs"
import queryString from "query-string"

import _isEmpty from "lodash/isEmpty"

import { Card, Tag, Tooltip } from "at-ui-nerv"
import { Link, withRouter } from "react-router-dom"
import {
  PLUGIN_CATEGORYS_MAP,
  PLATFORM_MAP
} from "../../../../../const/advanced"

import { getJfsImg } from "../../../utils/helper"
import "./index.scss"

class PluginItem extends React.PureComponent {
  handleClickTag = value => {
    const { history } = this.props

    history.push({
      pathname: "/search",
      search: queryString.stringify({
        keyword: value
      })
    })
  }

  render () {
    const {
      displayName,
      starCount,
      downloadCount,
      category,
      author,
      commentCount,
      desc,
      cover,
      version,
      editedAt,
      tags,
      _id,
      platform
    } = this.props

    const categoryInfo = PLUGIN_CATEGORYS_MAP.get(category) || {}

    return (
      <Card className="plugin-item" noHover={true}>
        <Link className="plugin-item__cover" to={`/plugin/view/${_id}`}>
          <img alt={displayName} src={getJfsImg(cover)} />
        </Link>
        <div className="plugin-item__meta">
          <Link className="plugin-meta__name" to={`/plugin/view/${_id}`}>
            <span
              className={`category__tag category__tag--${categoryInfo.name}`}
            >
              {categoryInfo.displayName}
            </span>
            {displayName}
          </Link>
          <div className="plugin-meta__desc">{desc || "暂无描述"}</div>
          <div className="plugin-meta__count">
            <Link className="plugin-meta__star" to={`/plugin/view/${_id}`}>
              <i className="icon icon-heart" /> 下载({downloadCount})
            </Link>
            <Link className="plugin-meta__star" to={`/plugin/view/${_id}`}>
              <i className="icon icon-heart" /> 收藏({starCount})
            </Link>
            <Link className="plugin-meta__comment" to={`/plugin/view/${_id}`}>
              <i className="icon icon-message-circle" /> 评论({commentCount})
            </Link>
          </div>
          <div className="plugin-meta__time">
            <div className="plugin-meta__update">
              更新日期：{dayjs(editedAt).format("YYYY-MM-DD")}
            </div>
            <div className="plugin-meta__update">当前版本：{version}</div>
            {_isEmpty(platform) ? null : (
              <div className="plugin-meta__platform">
                支持平台：
                {platform.map((item, key) => {
                  const info = PLATFORM_MAP.get(item)
                  return (
                    <Tooltip content={info.displayName} placement="bottom">
                      <div
                        key={key}
                        className={`platform platform--${info.name}`}
                      />
                    </Tooltip>
                  )
                })}
              </div>
            )}
          </div>

          <div className="plugin-meta__tags">
            {tags.map((item, key) => {
              const url = `/search?keyword=${item}`
              return (
                <Link to={url}>
                  <Tag className="tag" color="#eee" key={key}>
                    {item}
                  </Tag>
                </Link>
              )
            })}
          </div>
          <div className="plugin-meta__author">
            <img
              src={author.avatar}
              alt={author.jd_nickname}
              className="plugin-meta__avatar"
            />
            <span className="plugin-meta__nickname">{author.jd_nickname}</span>
          </div>
        </div>
      </Card>
    )
  }
}

export default withRouter(PluginItem)
