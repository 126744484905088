module.exports = {
  // 端口
  PORT_WEB: 8081,
  PORT_SERVER: 8082,
  // ERROR
  BAD_PARAMS: "BAD_PARAMS",
  UNKNOW_ERROR: "UNKNOW_ERROR",
  NO_AUTHENTICATION: "NO_AUTHENTICATION",
  DB_ERROR: "DB_ERROR",
  UPLOAD_ERROR: "UPLOAD_ERROR",
  // Magic Number
  PLUGIN: 1,
  COMMENT: 2,
  PLUGIN_COMPONENT: 1,
  PLUGIN_TEMPLATES: 2,
  PLUGIN_UI: 3,
  PLUGIN_SDK: 4,
  // Bucket
  BUCKET_JFS: "taro-ext",
  // role
  ADMIN_USERS: ["huangli47", "liweitao", "guiyonghong", "hejiasheng", "liweiliang3", "zhuminghui2", "liqinuo"],
  PLUGIN_COMPRESS_EXTENSIONS: ["tar", "zip"],
  PLUGIN_IMAGE_EXTENSIONS: ["jpg", "png", "jpeg"]
};