import React from "react"
import dayjs from "dayjs"

import { Input, Select, Button, Tag, Textarea, Rate } from "at-ui-nerv"

import client from "../../../../base/graphql"

import { CREATE_COMMENT } from "../../../../gql/comment"
import CommentPublish from "../publish/index"

import "./index.scss"

class CommentItem extends React.Component {
  constructor (props) {
    super(...arguments)
    this.state = {
      isReply: false
    }
  }

  handleReply = e => {
    this.setState({
      isReply: !this.state.isReply
    })
  }

  render () {
    const { isReply } = this.state
    const {
      content,
      user = {},
      rate,
      createdAt,
      category,
      categoryId,
      _id,
      replyComment
    } = this.props

    const { avatar, jd_nickname: nickname } = user

    return (
      <div className="comment-item">
        <div className="comment-item__header">
          <div className="comment-avatar">
            <img src={avatar} alt={nickname} />
          </div>
          <span className="header-username">{nickname}</span>
          <span className="header-time">
            {dayjs(createdAt).format("YYYY/MM/DD HH:mm")}
          </span>
          <Rate className="header-rates" allowHalf disabled value={rate} />
        </div>
        <div className="comment-item__body">
          {/* {replyComment ? (
            <div className="comment-item__header reply">
              <span className="header-username">
                {replyComment.user.jd_nickname}
              </span>
              <Rate
                className="header-rates"
                allowHalf
                disabled
                value={replyComment.rate}
              />
              <div>{replyComment.content}</div>
            </div>
          ) : null} */}
          {content}
        </div>
        <div className="comment-item__footer">
          {/* <div className="action-reply" onClick={this.handleReply}>
            <i className="icon icon-message-square" /> 回复
          </div> */}
        </div>
        {isReply ? (
          <CommentPublish
            categoryId={categoryId}
            category={category}
            replyComment={_id}
          />
        ) : null}
      </div>
    )
  }
}

export default CommentItem
