import React from "react"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"

import { Query } from "react-apollo"
import { Pagination } from "at-ui-nerv"

import Empty from "../../component/common/empty"
import Loading from "../../component/common/loading"
import PluginItem from "../../component/plugin/item/index"

import { USER_PLUGINS } from "../../gql/plugin"

import { getValueByKey, changeValueByKey } from "../../utils/query-string"

import "./index.scss"

const LIMIT = 10

class Space extends React.PureComponent {
  handlePageChange = page => {
    const { history } = this.props
    const search = changeValueByKey("page", page, {
      isStringify: true
    })
    history.push({
      search
    })
  }

  render () {
    const { id } = this.props.match.params
    const page = getValueByKey("page")

    return (
      <div className="page page--space main">
        <Query
          query={USER_PLUGINS}
          variables={{
            userId: id,
            pageInfo: {
              skip: (page - 1) * LIMIT
            }
          }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />
            }

            const { count, plugins } = data.res

            if (_isEmpty(plugins)) {
              return <Empty />
            }

            const user = _get(plugins, "[0].author")

            return (
              <div className="space-container">
                {user ? (
                  <div className="user-card">
                    <div className="user-info">
                      <div className="avatar">
                        <img src={user.avatar} alt="" />
                      </div>
                      <span className="nickname">{user.jd_nickname}</span>
                    </div>
                  </div>
                ) : null}
                {plugins.map(item => (
                  <PluginItem key={item._id} {...item} />
                ))}
                <Pagination
                  showTotal
                  total={count}
                  current={page}
                  onPageChange={this.handlePageChange}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default Space
