import React from "react"
import dayjs from "dayjs"

import { Query } from "react-apollo"
import { Pagination, Table, Button, Modal } from "at-ui-nerv"
import { withRouter, Link } from "react-router-dom"

import Loading from "@web/component/common/loading"

import { ADMIN_USERS } from "../../../../../const/index"

import {
  PLUGIN_REVIEW_FINISH,
  PLUGIN_REVIEW_PENDING
} from "../../../../../const/status"

import client from "../../../base/graphql"

import { PLUGIN_REVIEW, PLUGINS, PLUGIN_OFF_SHELVES } from "../../../gql/plugin"
import {
  getValueByKey,
  pickQueryString,
  changeValueByKey
} from "../../../utils/query-string"
import withMe from "../../../hoc/me"

import "./index.scss"

const LIMIT = 10

class PluginList extends React.Component {
  COLUMNS = [
    {
      title: "id",
      component: item => {
        return (
          <div>
            <a href={`/plugin/view/${item._id}`}>{item._id}</a>
          </div>
        )
      }
    },
    {
      title: "作者",
      component: item => {
        return <div>{item.author.jd_nickname}</div>
      }
    },

    {
      title: "物料名称",
      key: "displayName"
    },
    {
      title: "压缩包",
      component: item => {
        return (
          <div>
            <a download={item.filePath} href={item.filePath}>
              下载
            </a>
          </div>
        )
      }
    },
    {
      title: "更新时间",
      component: item => {
        return <div>{dayjs(item.editedAt).format("YYYY-MM-DD HH:mm")}</div>
      }
    },
    {
      title: "内容",
      component: item => {
        return (
          <div>
            <Button onClick={this.toggleModal.bind(this, item)}>查看</Button>
          </div>
        )
      }
    },
    {
      title: "状态",
      component: item => {
        let value = ""
        switch (item.status) {
          case PLUGIN_REVIEW_FINISH:
            value = "已审核"
            break
          default:
            value = "未审核"
        }
        return <div>{value}</div>
      }
    },
    {
      title: "操作",
      component: item => {
        return (
          <Button.Group>
            <Button
              disabled={item.status !== PLUGIN_REVIEW_PENDING}
              onClick={() => {
                client.mutate({
                  mutation: PLUGIN_REVIEW,
                  variables: {
                    id: item._id
                  }
                })
              }}
            >
              审核通过
            </Button>
            <Button
              disabled={item.status === PLUGIN_OFF_SHELVES}
              onClick={() => {
                client.mutate({
                  mutation: PLUGIN_OFF_SHELVES,
                  variables: {
                    id: item._id
                  }
                })
              }}
            >
              下架
            </Button>
          </Button.Group>
        )
      }
    }
  ]

  state = {
    showContent: false
  }

  toggleModal = (item = {}) => {
    this.setState({
      content: item.content ? item.content : "",
      showContent: !this.state.showContent
    })
  }

  handlePageChange = page => {
    const { history } = this.props
    const search = changeValueByKey("page", page, {
      isStringify: true
    })
    history.push({
      search
    })
  }

  render () {
    const page = getValueByKey("page")
    const filter = pickQueryString(["sort", "displayName", "status"])

    const { showContent, content } = this.state

    filter.status = filter.status
      ? parseInt(filter.status)
      : PLUGIN_REVIEW_PENDING

    return (
      <div className="page--console page">
        <div className="filter-bar">
          <Link to={`?status=${PLUGIN_REVIEW_PENDING}`}>未审核</Link>
          <Link to={`?status=${PLUGIN_REVIEW_FINISH}`}>已审核</Link>
        </div>
        <Query
          query={PLUGINS}
          variables={{
            filter,
            pageInfo: {
              skip: (page - 1) * LIMIT
            }
          }}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return <Loading />
            }
            if (error) {
              return <pre>{error}</pre>
            }

            const { count, plugins } = data.res

            return (
              <div className="plugin-list">
                <Table stripe border columns={this.COLUMNS} data={plugins} />
                <Pagination
                  showTotal
                  total={count}
                  current={page}
                  onPageChange={this.handlePageChange}
                />
                <Modal
                  onCancel={this.toggleModal}
                  showHead={false}
                  modalStyle={{
                    overflowY: "scroll",
                    maxHeight: "80vh"
                  }}
                  value={showContent}
                >
                  <Modal.body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content
                      }}
                    />
                  </Modal.body>
                </Modal>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withMe(withRouter(PluginList), {
  forceAuth: true,
  guard: me => {
    return ADMIN_USERS.includes(me.username)
  }
})
