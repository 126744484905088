import React from "react"
import { Breadcrumb } from "at-ui-nerv"
import { Route, Switch, NavLink } from "react-router-dom"

import Plugins from "./plugin/index"
import Comments from "./comment/index"

import "./index.scss"

class MyPage extends React.PureComponent {
  render () {
    return (
      <div className="page page--console main">
        <div className="at-tabs__header">
          <div className="at-tabs__extra">
            <div />
          </div>
          <div className="at-tabs__nav">
            <div className="at-tabs__nav-wrap">
              <div className="at-tabs__nav-scroll">
                <div className="at-tabs-nav">
                  <NavLink
                    className="at-tabs-nav__item"
                    activeClassName="at-tabs-nav__item--active"
                    to="/console/plugin"
                  >
                    物料列表
                  </NavLink>
                  <NavLink
                    className="at-tabs-nav__item"
                    activeClassName="at-tabs-nav__item--active"
                    to="/console/comment"
                  >
                    评论列表
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route path="/console/plugin" component={Plugins} />
          <Route path="/console/comment" component={Comments} />
        </Switch>
      </div>
    )
  }
}

export default MyPage
