import React from "react"
import dayjs from "dayjs"
import classnames from "classnames"
import _isEmpty from "lodash/isEmpty"

import { Card, Tag, Tooltip } from "at-ui-nerv"
import { Mutation } from "react-apollo"
import { Link, withRouter } from "react-router-dom"

import {
  PLATFORM_MAP,
  PLUGIN_CATEGORYS_MAP
} from "../../../../../../const/advanced"
import { getJfsImg } from "../../../../utils/helper"
import { PLUGIN_DELETE } from "../../../../gql/plugin"

import "./index.scss"

class PluginItem extends React.PureComponent {
  state = {
    isDelete: false
  }

  deletePlugin (action) {
    const value = window.confirm("此操作需要非常谨慎，您确定要这么做吗？")
    if (value) {
      action()
    }
  }

  render () {
    const {
      displayName,
      starCount,
      downloadCount,
      category,
      author,
      commentCount,
      desc,
      cover,
      version,
      editedAt,
      tags,
      _id,
      platform
    } = this.props
    const { isDelete } = this.state

    const categoryInfo = PLUGIN_CATEGORYS_MAP.get(category) || {}
    const deleteVariable = {
      id: _id
    }

    return (
      <Card
        className={classnames("plugin-item", {
          "plugin-item--deleted": isDelete
        })}
        noHover={true}
      >
        <Link className="plugin-item__cover" to={`/plugin/view/${_id}`}>
          <img alt={displayName} src={getJfsImg(cover)} />
        </Link>
        <div className="plugin-item__meta">
          <Mutation
            mutation={PLUGIN_DELETE}
            variables={deleteVariable}
            onCompleted={() => {
              this.setState({
                isDelete: true
              })
            }}
          >
            {(deletePlugin, { loading }) => {
              return (
                <div
                  onClick={this.deletePlugin.bind(this, deletePlugin)}
                  className={classnames("plugin-meta__remove", {
                    "plugin-meta__remove--loading": loading
                  })}
                >
                  <i className="icon icon-trash-2" />
                </div>
              )
            }}
          </Mutation>

          <Link className="plugin-meta__name" to={`/plugin/view/${_id}`}>
            <span
              className={`category__tag category__tag--${categoryInfo.name}`}
            >
              {categoryInfo.displayName}
            </span>
            {displayName}
          </Link>
          <div className="plugin-meta__desc">{desc || "暂无描述"}</div>
          <div className="plugin-meta__count">
            <Link className="plugin-meta__star" to={`/plugin/view/${_id}`}>
              <i className="icon icon-heart" /> 下载({downloadCount})
            </Link>
            <Link className="plugin-meta__star" to={`/plugin/view/${_id}`}>
              <i className="icon icon-heart" /> 收藏({starCount})
            </Link>
            <Link className="plugin-meta__comment" to={`/plugin/view/${_id}`}>
              <i className="icon icon-message-circle" /> 评论({commentCount})
            </Link>
          </div>
          <div className="plugin-meta__time">
            <div className="plugin-meta__update">
              更新日期：{dayjs(editedAt).format("YYYY-MM-DD")}
            </div>
            <div className="plugin-meta__update">当前版本：{version}</div>
            {_isEmpty(platform) ? null : (
              <div className="plugin-meta__platform">
                支持平台：
                {platform.map((item, key) => {
                  const info = PLATFORM_MAP.get(item)
                  return (
                    <Tooltip content={info.displayName} placement="bottom">
                      <div
                        key={key}
                        className={`platform platform--${info.name}`}
                      />
                    </Tooltip>
                  )
                })}
              </div>
            )}
          </div>
          <div className="plugin-meta__tags">
            {tags.map((item, key) => {
              return (
                <Tag className="tag" color="#eee" key={key}>
                  {item}
                </Tag>
              )
            })}
          </div>
          <div className="plugin-meta__author">
            <img
              src={author.avatar}
              alt={author.jd_nickname}
              className="plugin-meta__avatar"
            />
            <span className="plugin-meta__nickname">{author.jd_nickname}</span>
          </div>
        </div>
      </Card>
    )
  }
}

export default withRouter(PluginItem)
