import React from "react"

import { Button } from "at-ui-nerv"

import { Link } from "react-router-dom"

import SearchInput from "../search-input/index"

import "./index.scss"

class Home extends React.PureComponent {
  render () {
    return (
      <div className="home__banner">
        <div className="main">
          <h1 className="banner-slogan">让每一个轮子产生价值</h1>
          <SearchInput placeholder="0 个物料" className="banner-search" />

          <Link to="/plugin/form">
            <Button className="banner-submit" type="primary">
              发布物料
            </Button>
          </Link>
        </div>
      </div>
    )
  }
}

export default Home
