import React from "react"

import { getJfsImg } from "../../../../utils/helper"
import { PLUGIN_NORMAL } from "../../../../../../const/status"

import "./index.scss"

class DetailContent extends React.PureComponent {
  render () {
    const { status, content, cover } = this.props

    return (
      <div className="content-container">
        <div
          className="markdown-body"
          dangerouslySetInnerHTML={{
            __html:
              status === PLUGIN_NORMAL
                ? "<div class='syncing-md'>正在解析 MarkDown 请稍后重试</div>"
                : content
          }}
        />
        <div className="cover">
          <img src={getJfsImg(cover)} alt="封面图" />
        </div>
      </div>
    )
  }
}

export default DetailContent
