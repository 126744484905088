import React from "react"
import ReactDOM from "react-dom"

import { ApolloProvider } from "react-apollo"
import { BrowserRouter as Router } from "react-router-dom"

import client from "./base/graphql"
import Routes from "./base/routes"

import Header from "./component/common/header/header"
import Footer from "./component/common/footer/footer"
import ScrollToTop from "./component/common/scroll-top"

import "at-ui-style"
import "./index.scss"

const App = () => (
  <Router>
    <ApolloProvider client={client}>
      <Header />
      <div className="page-container">
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </div>
      <Footer />
    </ApolloProvider>
  </Router>
)

ReactDOM.render(<App />, document.getElementById("root"))
