import React from "react"

import _uniq from "lodash/uniq"
import _assignWith from "lodash/assignWith"
import _pick from "lodash/pick"
import _pickBy from "lodash/pickBy"
import _isEmpty from "lodash/isEmpty"
import _identity from "lodash/identity"

import semver from "semver"
import { Query } from "react-apollo"

import { Link } from "react-router-dom"

import {
  Input,
  Button,
  Checkbox,
  Tag,
  Select,
  Card,
  Breadcrumb,
  Message,
  Textarea
} from "at-ui-nerv"

import Loading from "../../../component/common/loading"
import withMe from "../../../hoc/me"
import client from "../../../base/graphql"
import Upload from "../../../component/common/upload"
import TextOverflow from "../../../component/common/text-overflow"
import FormItem from "../../../component/common/form-item/index"

import { getJfsUrl, getJfsImg, getUploadAccept } from "../../../utils/helper"
import { CREATE_PLUGIN, PLUGIN, UPDATE_PLUGIN } from "../../../gql/plugin"

import {
  PLATFORM_MAP,
  PLUGIN_CATEGORYS_MAP
} from "../../../../../const/advanced"
import {
  PLUGIN_IMAGE_EXTENSIONS,
  PLUGIN_COMPRESS_EXTENSIONS
} from "../../../../../const/index"

import "./index.scss"
import "simplemde/src/css/simplemde.css"

const PLATFORM_ARRAY = [...PLATFORM_MAP.values()]
const CATEGORY_ARRAY = [...PLUGIN_CATEGORYS_MAP.values()]

const VERSION_TIP = (
  <a target="blank" href="https://semver.org/lang/zh-CN/">
    semver 版本
  </a>
)

class PluginForm extends React.PureComponent {
  constructor () {
    super(...arguments)

    this.simplemde = null
    this.state = {}
  }

  handlePlatform = value => {
    this.setState({
      platform: value
    })
  }

  handleSubmit = async plugin => {
    const changesValue = _pick(plugin, Object.keys(this.state))
    const newPlugin = _pick(changesValue, [
      "desc",
      "tags",
      "cover",
      "npmUrl",
      "gitUrl",
      "category",
      "content",
      "version",
      "filePath",
      "displayName",
      "contentPath",
      "platform"
    ])

    const { id } = this.props.match.params
    const {
      desc,
      tags,
      cover,
      version,
      filePath,
      category,
      displayName,
      contentPath
    } = newPlugin

    const isEdit = !!id

    if (!isEdit) {
      if (!displayName) {
        return Message.error("名称 是必填参数")
      }

      if (!desc) {
        return Message.error("描述 是必填参数")
      }

      if (!tags || tags.length === 0) {
        return Message.error("标签 是必填参数")
      }

      if (!cover) {
        return Message.error("封面图 是必填参数")
      }

      if (!category) {
        return Message.error("种类 是必填参数")
      }

      if (!filePath) {
        return Message.error("物料压缩包 是必填参数")
      }

      if (!contentPath) {
        return Message.error("物料详情 是必填参数")
      }

      if (!semver.valid(version)) {
        return Message.error("版本号不符合 semver 结构")
      }
    }

    const { data } = await client.mutate({
      variables: {
        id,
        plugin: _pickBy(newPlugin, _identity)
      },
      mutation: id ? UPDATE_PLUGIN : CREATE_PLUGIN
    })

    this.props.history.push(`/plugin/view/${data.plugin._id}`)
  }

  setFormValue = (value, e) => {
    const { name } = e.target

    this.setState({
      [name]: value
    })
  }

  setDesc = e => {
    const { value } = e.target

    this.setState({
      desc: value
    })
  }

  handleTag = e => {
    const { keyCode } = e
    const { value } = e.target

    const { tags = [] } = this.state

    if (keyCode === 13) {
      if (tags.length >= 5) {
        return Message.error("标签最多只有 5 个")
      }

      tags.push(value)
      this.setState({
        tags: _uniq(tags)
      })

      e.target.value = null
    }
  }

  handleCloseTag = (value, tags) => {
    this.setState({
      tags: tags.filter(item => item !== value)
    })
  }

  handleSelectChange = value => {
    this.setState({
      category: parseInt(value)
    })
  }

  handleFileUpload = ({ file }) => {
    this.setState({
      pluginFileName: file.filename,
      filePath: getJfsUrl(file.path)
    })
  }

  handleCoverUpload = ({ file }) => {
    this.setState({
      cover: file.path
    })
  }

  handleMDUpload = ({ file }) => {
    this.setState({
      contentFileName: file.filename,
      contentPath: file.path
    })
  }

  render () {
    const { id } = this.props.match.params

    const isEdit = !!id

    return (
      <form id="pluginForm" className="page page--plugin-form main">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">物料市场</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>发布物料</Breadcrumb.Item>
        </Breadcrumb>
        <Query query={PLUGIN} variables={{ id }} skip={!isEdit}>
          {({ loading, data }) => {
            if (loading) {
              return <Loading />
            }

            if (_isEmpty(data)) {
              data = {
                plugin: {}
              }
            }

            const plugin = _assignWith(data.plugin, this.state)

            if (this.state.platform) {
              plugin.platform = this.state.platform
            }

            const {
              desc = "",
              tags,
              cover,
              gitUrl,
              npmUrl,
              version,
              category,
              platform = [],
              displayName
            } = plugin

            return (
              <Card noHover={true}>
                <h4 slot="title">发布物料</h4>
                <FormItem label="名称" labelFor="displayName" isRequired>
                  <Input
                    value={displayName}
                    onInput={this.setFormValue}
                    name="displayName"
                  />
                </FormItem>
                <FormItem label="分类" isRequired>
                  <Select
                    clearable
                    size="large"
                    value={category}
                    onChange={this.handleSelectChange}
                  >
                    {CATEGORY_ARRAY.map(item => {
                      return (
                        <Select.Option value={item.value} key={item.value}>
                          {item.displayName}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </FormItem>
                <FormItem
                  isRequired
                  label="版本"
                  labelFor="version"
                  tip={VERSION_TIP}
                >
                  <Input
                    value={version}
                    onInput={this.setFormValue}
                    name="version"
                  />
                </FormItem>
                <FormItem
                  isRequired
                  label="简介"
                  labelFor="desc"
                  tip={<TextOverflow use={desc.length} total={150} />}
                >
                  <Textarea
                    minRows={4}
                    maxRows={6}
                    resize="none"
                    value={desc}
                    placeholder="请输入插件的简介"
                    onInput={this.setDesc}
                    name="desc"
                  />
                </FormItem>
                <FormItem
                  className="form-item--tags"
                  isRequired
                  label="标签"
                  labelFor="tagValue"
                  tip="回车添加多个标签"
                >
                  {tags && tags.length > 0 ? (
                    <ul className="tags">
                      {tags.map((item, key) => {
                        return (
                          <li>
                            <Tag
                              closable
                              onClose={this.handleCloseTag.bind(
                                null,
                                item,
                                tags
                              )}
                              key={key}
                            >
                              {item}
                            </Tag>
                          </li>
                        )
                      })}
                    </ul>
                  ) : null}
                  <div class="at-input">
                    <input
                      onKeyDown={this.handleTag}
                      class="at-input__original"
                      type="text"
                      name="tagValue"
                    />
                  </div>
                </FormItem>
                <FormItem label="支持平台" labelFor="platform" isRequired>
                  <Checkbox.Group
                    value={platform}
                    onChange={this.handlePlatform}
                  >
                    {PLATFORM_ARRAY.map(item => {
                      return (
                        <Checkbox key={item.value} label={item.value}>
                          {item.displayName}
                        </Checkbox>
                      )
                    })}
                  </Checkbox.Group>
                </FormItem>
                <FormItem label="github 地址" labelFor="gitUrl">
                  <Input
                    name="gitUrl"
                    value={gitUrl}
                    onInput={this.setFormValue}
                    prepend={<span>git</span>}
                  />
                </FormItem>
                <FormItem label="npm 地址" labelFor="npmUrl">
                  <Input
                    name="npmUrl"
                    value={npmUrl}
                    onInput={this.setFormValue}
                    prepend={<span>npm</span>}
                  />
                </FormItem>
                <FormItem
                  className="form-item--cover"
                  label="物料截图"
                  labelFor="cover"
                  isRequired
                  tip={`支持${PLUGIN_IMAGE_EXTENSIONS.join("、")}`}
                >
                  {cover ? <img src={getJfsImg(cover)} alt="封面图" /> : null}
                  <Upload
                    onUploadFinish={this.handleCoverUpload}
                    accept={getUploadAccept(PLUGIN_IMAGE_EXTENSIONS)}
                  />
                </FormItem>
                <FormItem
                  label="物料压缩包"
                  labelFor="filePath"
                  isRequired
                  tip={`支持${PLUGIN_COMPRESS_EXTENSIONS.join("、")}`}
                >
                  {this.state.pluginFileName}
                  <Upload
                    onUploadFinish={this.handleFileUpload}
                    accept=".tar,.zip"
                  />
                </FormItem>
                <FormItem
                  isRequired
                  label="说明文档"
                  tip="仅支持markdown格式"
                  labelFor="content"
                >
                  {this.state.contentFileName}
                  <Upload
                    isJFS={false}
                    onUploadFinish={this.handleMDUpload}
                    accept=".md"
                  />
                </FormItem>
                <Button onClick={this.handleSubmit.bind(this, plugin)}>
                  确认提交
                </Button>
              </Card>
            )
          }}
        </Query>
      </form>
    )
  }
}

export default withMe(PluginForm, {
  forceAuth: true
})
