import React from "react"
import { NavLink } from "react-router-dom"

import _isNil from "lodash/isNil"
import _trim from "lodash/trim"
import _pick from "lodash/pick"
import _isEqual from "lodash/isEqual"
import _isString from "lodash/isString"
import _isObject from "lodash/isObject"

class AppNavLink extends React.Component {
  isActive = (match, location) => {
    const { to } = this.props

    if (_isString(to)) {
      const [pathname, search = ""] = to.split("?")

      return (
        location.pathname === pathname && location.search.indexOf(search) !== -1
      )
    }

    return false
  }

  render () {
    return (
      <NavLink isActive={this.isActive} {...this.props}>
        {this.props.children}
      </NavLink>
    )
  }
}

export default AppNavLink
