import React from "react"

import { Query } from "react-apollo"

import Loading from "../loading"
import { COMMENTS } from "../../../gql/comment"
import { COMMENT_STATUS_REVIEWED } from "../../../../../const/index"

import CommentItem from "./item/index"

class Comment extends React.Component {
  render () {
    const { category = 1, categoryId } = this.props
    const variable = {
      filter: { category, categoryId, status: COMMENT_STATUS_REVIEWED }
    }

    return (
      <div className="comment-list-container">
        <Query query={COMMENTS} variables={variable}>
          {({ loading, data, error }) => {
            if (loading) {
              return <Loading />
            }

            if (error) {
              return console.warn(error)
            }

            const { comments } = data.res

            return (
              <div className="comment-list">
                {comments.map(item => {
                  return (
                    <CommentItem
                      key={item._id}
                      {...item}
                      category={category}
                      categoryId={categoryId}
                    />
                  )
                })}
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default Comment
