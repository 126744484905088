import React from "react"
import classnames from "classnames"

import "./index.scss"

class TextOverflow extends React.Component {
  render () {
    const { use, total, className, style } = this.props

    const rootClass = classnames("text-overflow", className, {
      "text-overflow--max": use >= total
    })

    return (
      <div className={rootClass} style={style}>
        <span>{use}</span> / {total}
      </div>
    )
  }
}

export default TextOverflow
