import React from "react"
import _isEmpty from "lodash/isEmpty"

import { Query } from "react-apollo"
import { Pagination } from "at-ui-nerv"

import Empty from "../../../component/common/empty"
import Loading from "@web/component/common/loading"
import PluginItem from "../../../component/plugin/item/index"

import { STAR_RESOURCES } from "../../../gql/star"

import { getValueByKey, changeValueByKey } from "../../../utils/query-string"

class StarList extends React.PureComponent {
  handlePageChange = page => {
    const { history } = this.props

    const search = changeValueByKey("page", page, {
      isStringify: true
    })

    history.push({
      search
    })
  }

  render () {
    const page = getValueByKey("page")

    return (
      <div className="page page--my-star">
        <Query fetchPolicy='network-only' query={STAR_RESOURCES}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />
            }

            const { count, resources } = data.stars

            if (_isEmpty(resources)) {
              return <Empty />
            }

            return (
              <div className="my-star-resource-list">
                {resources.map(item => {
                  return <PluginItem key={item._id} {...item} />
                })}
                <Pagination
                  showTotal
                  total={count}
                  current={page}
                  onPageChange={this.handlePageChange}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default StarList
