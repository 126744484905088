import React from "react"

import { Footer as TaroFooter } from "@jd/taro-header-footer"

class Footer extends React.Component {
  render () {
    return <TaroFooter />
  }
}

export default Footer
