module.exports = {
  H5: 1,
  // H5
  WEAPP: 2,
  // 微信小程序
  SMART: 3,
  // 百度小程序
  ALIPAY: 4,
  // 支付宝小程序
  TOUTIAO: 5,
  // 头条小程序
  QUICK_APP: 6,
  // 快应用
  REACT_NATIVE: 7,
  // react native
  QQ: 8,
  // QQ 小程序
  JD: 9 // JD 小程序

};