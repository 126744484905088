import React from "react"
import gql from "graphql-tag"

import { Card } from "at-ui-nerv"
import { Query } from "react-apollo"
import { Link } from "react-router-dom"

import Loading from "@web/component/common/loading"

import "./index.scss"

const RANKING = gql`
  query {
    ranking {
      count
      user {
        _id
        avatar
        email
        jd_nickname
        username
        orgName
      }
    }
  }
`

class Ranking extends React.Component {
  state = {
    adList: []
  }

  async componentDidMount () {
    if (fetch) {
      const res = await fetch("https://services.jd.com/neos/form?id=10066&page=0&size=10").then(res => res.json())

      if (res.code !== 0) return

      this.setState({
        adList: res.data
      })
    }
  }

  render () {
    const { adList } = this.state
    return (
      <div className="user-ranking">
        <div className='ranking-activity'>
          {
            adList.map((item, key) => {
              return <a key={key} target="_blank" rel="noopener noreferrer" href={item.href}>
                <img src={item.ad} alt="" />
              </a>
            })
          }
        </div>
        <h3>作者排行</h3>
        <Card className="ranking-list" noHover={true}>
          <Query query={RANKING}>
            {({ loading, error, data }) => {
              if (loading) {
                return <Loading />
              }
              if (error) {
                return <pre>{error}</pre>
              }

              return data.ranking.map(item => {
                const { user, count } = item
                return (
                  <Link
                    to={`/space/${user._id}`}
                    key={user._id}
                    className="ranking-list__item"
                  >
                    <img
                      className="ranking-list__item-avatar"
                      src={user.avatar}
                      alt={user.jd_nickname}
                    />
                    <div className="ranking-list__item-meta">
                      <p>昵称：{user.jd_nickname} </p>
                      <p>物料数：{count}</p>
                    </div>
                  </Link>
                )
              })
            }}
          </Query>
        </Card>
      </div>
    )
  }
}

export default Ranking
