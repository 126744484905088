import React from "react"

import { Query } from "react-apollo"
import { Breadcrumb } from "at-ui-nerv"
import { Link, Redirect } from "react-router-dom"

import withMe from "../../../hoc/me"
import Loading from "../../../component/common/loading"

import DetailBase from "../../../component/plugin/detail/base/index"
import UserCard from "../../../component/plugin/detail/user/index"
import DetailContent from "../../../component/plugin/detail/content/index"

import Comments from "../../../component/common/comment/index"
import CommentPublish from "../../../component/common/comment/publish/index"

import { PLUGIN } from "../../../gql/plugin"
import { PLUGIN_REVIEW_FINISH } from "../../../../../const/status"

import "./index.scss"
import "highlight.js/styles/atom-one-dark.css"

class PluginDetail extends React.PureComponent {
  render () {
    const { me } = this.props
    const { id } = this.props.match.params

    return (
      <div className="page page--plugin-detail main">
        <Query query={PLUGIN} variables={{ id }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />
            }

            if (error) {
              return console.error(error)
            }

            const { author, status } = data.plugin

            // 如果未审核 并且非自己的插件 跳转 404
            if (status !== PLUGIN_REVIEW_FINISH && me._id !== author._id) {
              return <Redirect to="/404" />
            }

            return (
              <div>
                <div className="plugin-detail__nav-bar">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">物料市场</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>物料详情</Breadcrumb.Item>
                  </Breadcrumb>
                  {me._id === author._id ? (
                    <div className="header__actions">
                      <Link to={`/plugin/form/${id}`}>编辑</Link>
                    </div>
                  ) : null}
                </div>
                <DetailBase {...data.plugin} />
                <DetailContent {...data.plugin} />
                <UserCard {...author} />
                <div className="plugin-comment">
                  <CommentPublish
                    className="page__comment-publish"
                    categoryId={id}
                    category={1}
                  />
                  <Comments category={1} categoryId={id} />
                </div>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withMe(PluginDetail)
