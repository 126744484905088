import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _template from "lodash/template"

import ZhCh from "../../../const/zh-ch"

import { REGEX_URL } from "../../../const/regex"


const webpSupport =
  !![].map &&
  document
    .createElement("canvas")
    .toDataURL("image/webp")
    .indexOf("data:image/webp") == 0

/**
 * 获取登录链接
 * @param {Sring} url 回调URL
 */
export const getLoginUrl = (url = window.location.href) => {
  if (_isEmpty(url)) {
    return
  }


  const loginUrl = process.env.NODE_ENV === "production"
    ? "//uipaas-sso.jd.com/user/login/jd-idaas"
    : "//uipaas-sso-uat.jd.com/user/login/jd-idaas"
  return `${loginUrl}?returnUrl=${encodeURIComponent(url)}&appCode=taro&clientType=taro-pc`
}


export const getSsoLogoutUrl = () => {
  const loginOutUrl = process.env.NODE_ENV === "production"
    ? "//uipaas-sso.jd.com/user/logout"
    : "//uipaas-sso-uat.jd.com/user/logout"
  return loginOutUrl
}

/**
 * 获取上传文件的地址
 * @param {Sring} url 回调URL
 */
export const getJfsUrl = url => {
  if (_isEmpty(url)) {
    return
  }

  return `//storage.jd.com/${url}`
}

export const isUrl = url => {
  return REGEX_URL.test(url)
}

/**
 * JOI 类型报错处理
 * @param {String} type 对应的 JOI 错误
 * @param {Object} values 插值数据
 */
export const joiTipGenerate = (type, values) => {
  return _template(ZhCh[type])(values)
}

/**
 * 把 允许的上传文件后缀 换成 input accept 字符串
 * @param {Array} array 允许的上传数组
 * @return {String}
 */
export function getUploadAccept (array) {
  return array && array.map(item => `.${item}`).join(",")
}

/**
 * 为图片路径添加域名，及设置宽高
 * powerBy JD_wxapp
 */
export function getJfsImg (url, width, height = width) {
  if (!url) return ""

  if (url.indexOf("data:image") === 0 && url.indexOf("base64") != -1) {
    return url
  }

  // 去除链接中空白字符
  url = url.replace(/\s+/g, "")

  // 统一协议头
  url = url.replace(/^(https?:)?\/\//i, "http://")

  // 去除图片后缀的多余字符
  const rUrl = url.match(/(\S*(jpg|jpeg|png|webp|gif))\s*/g)
  if (!rUrl) return url

  url = rUrl[0]

  // 拼接域名和业务名
  if (!/^http/i.test(url)) {
    url = `http://img10.360buyimg.com/img/${url}`
  }

  // Gif 图不做后续处理
  if (/\.gif/i.test(url)) return url

  // 是否JFS 图片
  const isJfsImg = /jfs\//.test(url)

  // 非JFS 或CDN 图片不处理
  if (
    !isJfsImg ||
    !/(m|img\d{1,2})\.360buyimg\.com/.test(url) ||
    !/\.(jpg|jpeg|png|webp)/.test(url)
  ) {
    return url
  }

  // 设定宽高
  if (width) {
    url = url.replace(/(\/)(?:s\d+x\d+_)?(jfs\/)/, `$1s${width}x${height}_$2`)
  }

  // 从本地存储读取webp 的支持情况
  if (webpSupport && /\.(jpg|jpeg|png)/.test(url) && !/\.webp/.test(url)) {
    url += ".webp"
  }

  // 质量压缩
  if (/\.(jpg|jpeg)/.test(url)) {
    const level = { wifi: 80, "4g": 60, "3g": 40, "2g": 20 }[
      _get(navigator, "connection.effectiveType", "wifi")
    ]
    if (level) {
      url = url.replace(/(\.(jpg|jpeg))(!q\d{1,2})?/, `$1!q${level}`)
    }
  }

  // 分散域名
  const pool = [10, 11, 12, 13, 14, 20, 30] // 域名池
  const idx =
    (parseInt(url.substr(url.lastIndexOf("/") + 1, 8), 36) || 0) % pool.length
  url = url.replace(/(\/\/img)\d{1,2}(\.360buyimg\.com)/, `$1${pool[idx]}$2`)

  return url
}

export function getFileSize (number) {
  if (number < 1024) {
    return `${number}bytes`
  } else if (number > 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)}KB`
  } else if (number > 1048576) {
    return `${(number / 1048576).toFixed(1)}MB`
  }
}
