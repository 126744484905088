import React from "react"

import Banner from "../../component/common/banner/index"
import Ranking from "../../component/common/ranking/index"
import PluginList from "../../component/search/list"

class Home extends React.PureComponent {
  render () {
    return (
      <div className="page page--home">
        <Banner />

        <div className="main">
          <div className="home__content">
            <PluginList />
          </div>
          <div className="contribute-ranking">
            <Ranking />
          </div>
        </div>
      </div>
    )
  }
}

export default Home
