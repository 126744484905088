import React from "react"

import HomeTab from "../../component/home/tabs/index"
import Banner from "../../component/common/banner/index"
import Ranking from "../../component/common/ranking/index"
import PluginList from "../../component/plugin/list/index"

import "./index.scss"

class Home extends React.PureComponent {
  render () {
    return (
      <div className="page page--home">
        <Banner />

        <div className="main">
          <div className="home__content">
            <HomeTab />
            <PluginList />
          </div>
          <div className="contribute-ranking">
            <Ranking />
          </div>
        </div>
      </div>
    )
  }
}

export default Home
